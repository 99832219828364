import ReactGA from 'react-ga4';
import AppConstants from './AppConstants';
import { firebaseConfig, firebaseConfigStaging } from './Constant';

const config =
  AppConstants.APP_ENV === 'production'
    ? firebaseConfig
    : firebaseConfigStaging;

ReactGA.initialize([
  { trackingId: config.measurementId },
  { trackingId: config.measurementId2 },
]);

const GoogleAnalytics = ReactGA;

export default GoogleAnalytics;
